<template>
	<edit-template @confirm="submitForm('ruleForm')" @cancel="cancel" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <div class="form-body" style="padding: 35rem 0 0 60rem">
      <h4>基础信息</h4>
      <br>
      <el-form :model="FormData" :rules="rules" ref="ruleForm" label-width="100rem">
        <el-form-item label="选择考试" prop="exam_id">
          <el-select :popper-append-to-body="false" v-model="FormData.exam_id" placeholder="请选择" @change="examidChange">
            <el-option v-for="item in examArr" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择科目" prop="subject">
          <el-cascader v-model="FormData.subject" :append-to-body="false" :options="subjectArr" placeholder="请选择" @change="subjectidChange"></el-cascader>
        </el-form-item>
        <el-form-item label="题目数量" prop="repnum">
          <el-input-number v-model="FormData.repnum" :min="min" :max="1000" style="width: 140rem !important" :step="1" step-strictly=""></el-input-number>
          <span style="margin-left: 20rem; color: #b0b0b0">建议一题多问时,计为多个题目,方便数据分析,且最少1道题目</span>
        </el-form-item>
        <br>
        <h4>关联知识点</h4>
        <div class="knowledge" v-for="(item, index) in FormData.wiki" :key="index">
          <el-col :span="22" class="knowledge_box">
            <el-col :span="5">
              <div>
                <el-form-item label="题号">
                  <el-input v-model="FormData.wiki[index].serialnum" placeholder="请输入题号" @blur="serialnumBlur(item, index)" @focus="serialnumFocused(item, index)" style="min-width: 150rem; width: 10vw !important"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-form-item label="分数">
                  <el-input-number v-model="FormData.wiki[index].score" ref="number" :min="0.5" :max="99999" style="width: 140rem !important" :step="0.5" :precision="1" step-strictly="" @blur="scoreBlur(item, index)"></el-input-number>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="7">
              <div>
                <el-form-item label="涉及考点">
                  <el-cascader v-model="FormData.wiki[index].abilitys" :options="abilitysArr" :props="{ multiple: true }" :show-all-levels="false" :clearable="true" placeholder="请选择涉及考点" style="width: 10vw; min-width: 150rem" :append-to-body="false">
                  </el-cascader>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <el-form-item label="设置关联题">
                <el-select :popper-append-to-body="false" v-model="FormData.wiki[index].set_title" placeholder="请选择" @change="settitleChange(item, index)" style="width: 10vw; min-width: 150rem">
                  <el-option v-for="item in titleArr" :disabled="item.disabled" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-col>
          <!-- 关联知识点新增题目按钮 -->
          <el-col :span="2" class="add_knowledge">
            <el-button icon="el-icon-delete" circle="" @click="deleteKnowledge(item, index)"></el-button>
            <el-button icon="el-icon-plus" circle="" @click="addKnowledge" v-if="FormData.wiki.length - 1 == index"></el-button>
          </el-col>
        </div>
      </el-form>
    </div>
    
  
</edit-template>
</template>

<script>
import { getExam, examSubject, abilityList, create } from "./api";
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      // 题目最小数量
      min: 1,
      examArr: [], // 考试数据存放
      subjectArr: [], // 科目数据存放
      abilitysArr: [], // 涉及考点数据存放
      titleArr: [{ value: 1, label: "请输入题号", disabled: true }], // 关联题数据存放
      hosting: "",
      FormData: {
        exam_id: "", // 考试
        subject: "", // 科目
        repnum: 1, // 题目数量
        wiki: [
          {
            serialnum: "", //题号
            score: "", //	分数
            abilitys: "", //	知识点
            set_title: "", //关联题
            group_id: 1,
            only: 1,
          },
        ],
      },
      num: 1,
      rules: {
        exam_id: [{ required: true, message: "请选择考试", trigger: "change" }],
        subject: [{ required: true, message: "请选择考试科目", trigger: "change" }],
        repnum: [{ required: true, message: "请输入题目数量", trigger: "change" }],
      },
      marking: 0,
    };
  },

  methods: {
    scoreBlur(val) {
      if (val.score === undefined) {
        val.score = 1.0;
      }
    },
    // 获取考试科目
    examidChange(val) {
      // this.abilitysArr = []
      this.FormData.subject = "";
      this.clearKnowledge();
      examSubject(val).then((res) => {
        this.subjectArr = JSON.parse(
          JSON.stringify(res.data.data)
            .replace(/id/g, "value")
            .replace(/subject_name/g, "label")
            .replace(/child/g, "children")
        );
      });
    },
    // 获取关联知识点数据
    subjectidChange(val) {
      this.clearKnowledge();
      this.abilitysArr = [];
      abilityList(val[0]).then((res) => {
        this.abilitysArr = JSON.parse(
          JSON.stringify(res.data.data)
            .replace(/id/g, "value")
            .replace(/name/g, "label")
            .replace(/child/g, "children")
        );
      });
    },
    // 关联知识点删除题目按钮
    deleteKnowledge(val, index) {
      let str = val.only;
      if (this.FormData.wiki.length > 1) {
        this.FormData.wiki.splice(index, 1);
        this.min = this.FormData.wiki.length;
        this.titleArr = [];
        this.FormData.wiki.forEach((item, index_) => {
          this.titleArr.push({
            value: item.only,
            label: item.serialnum == "" ? "请输入题号" : item.serialnum,
            disabled: item.serialnum == "" ? true : false,
          });
          if (item.set_title == str) {
            item.set_title = "";
          }
        });
      } else {
        this.$message.error("至少保留一个关联知识点");
      }
    },
    // 关联知识点新增题目按钮
    addKnowledge() {
      if (this.FormData.wiki.length + 1 > this.FormData.repnum) {
        this.$message.error("关联知识点数量不能大于题目数量");
      } else {
        this.FormData.wiki.push({
          serialnum: "",
          score: "",
          abilitys: "",
          only: ++this.num,
          group_id: this.num,
          set_title: "",
        });
        this.min = this.FormData.wiki.length;
      }
      this.titleArr = [];
      this.FormData.wiki.forEach((item) => {
        this.titleArr.push({
          value: item.only,
          label: item.serialnum == "" ? "请输入题号" : item.serialnum,
          disabled: item.serialnum == "" ? true : false,
        });
      });
      this.listenInput();
    },
    serialnumBlur(item, index) {
      if (item.serialnum != "") {
        let tag = 0;
        this.FormData.wiki.forEach((i) => {
          if (i.serialnum != "") {
            if (i.serialnum == item.serialnum) {
              tag++;
              if (tag > 1) {
                item.serialnum = "";
                this.$message.error("题号不能重复");
              }
            }
          }
        });
        this.titleArr = [];
        this.FormData.wiki.forEach((item, n) => {
          this.titleArr.push({
            value: item.only,
            label: item.serialnum == "" ? "请输入题号" : item.serialnum,
            disabled: item.serialnum == "" ? true : false,
          });
        });
      } else {
        this.titleArr = [];
        this.FormData.wiki.forEach((item, n) => {
          this.titleArr.push({
            value: item.only,
            label: item.serialnum == "" ? "请输入题号" : item.serialnum,
            disabled: item.serialnum == "" ? true : false,
          });
        });
      }
    },
    settitleChange(val, index) {
      val.group_id = val.set_title;
      // this.FormData.wiki.forEach(item => {
      // 	if (val.set_title == item.only) {

      // 		// if (item.only == item.group_id) {
      // 		// 	this.FormData.wiki[index].group_id = item.only
      // 		// 	this.FormData.wiki[index].score = item.score
      // 		// } else {
      // 		// 	this.FormData.wiki[index].group_id = item.group_id
      // 		// 	this.FormData.wiki[index].score = item.score
      // 		// }
      // 	}
      // })
      // this.FormData.wiki.forEach(item => {
      // 	if (val.group_id == item.only) {
      // 		val.group_id = item.group_id
      // 	}
      // 	if (val.only == item.group_id) {
      // 		item.group_id = val.group_id
      // 	}
      // })
    },
    serialnumFocused(item, index) {
      this.hosting = item.serialnum;
    },
    // 取消按钮
    cancel() {
      this.$confirm("是否取消新增", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.back();
      }).catch(()=>{});
    },
    // 保存按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          for (let index = 0; index < this.FormData.wiki.length; index++) {
            if (this.FormData.wiki[index].serialnum == "") {
              this.$message.error("题号不能为空");
              return;
            }
            // if (this.FormData.wiki[index].abilitys == "") {
            //   this.$message.error("知识点不能为空");
            //   return;
            // }
            if (this.FormData.wiki[index].abilitys.length > 50) {
              this.$message.error("知识点范围太多请重新选择");
              return;
            }
          }
          create(this.FormData).then((res) => {
            if (res.data.error.errorCode === 0) {
              this.$message({
                type: "success",
                message: "新增成功!",
              });
              this.$store.commit("setPage", 1);
              this.$router.back();
            }
						// else {
            //   this.$message({
            //     type: "error",
            //     message: res.data.error.errorMsg,
            //   });
            // }
          });
        }
      });
    },
    // 清空考点知识库数据
    clearKnowledge() {
      this.FormData.wiki.forEach((item) => {
        item.abilitys = "";
      });
    },
    listenInput() {
      setTimeout(() => {
        let refs = this.$refs.number;
        refs.forEach((item) => {
          let input = item.$el.querySelector("input.el-input__inner");
          input.addEventListener("input", () => {
            let value = input.value;
            input.value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3').replace(/^\./g, '');;
          });
        });
      }, 0);
    },
  },
  created() {
    // 获取考试数据
    getExam().then((res) => {
      this.examArr = JSON.parse(
        JSON.stringify(res.data.data)
          .replace(/exam_id/g, "value")
          .replace(/exam_name/g, "label")
      );
    });

    this.listenInput();
  },
};
</script>

<style lang="scss" scoped>
.knowledge {
  margin-top: 24rem;
  display: flex;
  // justify-content: center;
  align-items: center;
  position: relative;
  width: 99%;
  .knowledge_box {
    display: flex;
    padding-top: 30rem;
    background-color: #f4f4f4;
    text-align: center;
  }
  .add_knowledge {
    display: flex;
    // padding-left: 40rem;
    position: absolute;
    top: 30rem;
    right: -10rem;
  }
}
@media only screen and (max-width: 1500rem) {
  .knowledge {
    width: 1100rem;
  }
}
</style>
